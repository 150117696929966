import loadable from '@loadable/component';
import renderRoute, { IRoute } from './renderRoutes';
import { HashRouter as Router, Routes } from 'react-router-dom';

const routes: IRoute[] = [
  {
    path: '/home/*', // v6废除exact, 默认都是精确匹配，采用此写法配置不精确匹配
    component: loadable(
      () => import('../pages/home')
    ),
    children: [
      {
        path: 'user/*',
        component: loadable(
          () => import('../pages/home/user')
        )
      },
    ],
  },
  {
    path: '/crm/*',
    component: loadable(
      () => import('../pages/crm')
    ),
    children: [
    ],
  },
  {
    path: '/my/*',
    component: loadable(
      () => import('../pages/my')
    ),
    children: [
    ],
  },
  {
    path: '/message/*',
    component: loadable(
      () => import('../pages/message')
    ),
    children: [
      {
        path: 'list',
        component: loadable(
          () => import('../pages/message/list')
        )
      },
      {
        path: 'detail',
        component: loadable(
          () => import('../pages/message/detail')
        )
      },
      {
        path: '*',
        redirect: 'list',
      },
    ],
  },
  {
    path: '/business/*',
    component: loadable(
      () => import('../pages/business')
    ),
    children: [
      {
        path: 'add',
        component: loadable(
          () => import('../pages/business/add')
        )
      },
      {
        path: '*',
        redirect: 'add',
      },
    ],
  },
  {
    path: '/inquiry/*',
    component: loadable(
      () => import('../pages/inquiry')
    ),
    children: [
      {
        path: 'add',
        component: loadable(
          () => import('../pages/inquiry/add')
        )
      },
      {
        path: 'detail',
        component: loadable(
          () => import('../pages/inquiry/detail')
        )
      },
      {
        path: '*',
        redirect: 'add',
      },
    ],
  },
  {
    path: '/customer/*',
    component: loadable(
      () => import('../pages/customer')
    ),
    children: [
      {
        path: 'add',
        component: loadable(
          () => import('../pages/customer/add')
        )
      },
      {
        path: '*',
        redirect: 'add',
      },
    ],
  },
  {
    path: '/ccList/*',
    component: loadable(
      () => import('../pages/ccList')
    ),
    children: [
      {
        path: 'detail',
        component: loadable(
          () => import('../pages/ccList/detail')
        )
      },
      {
        path: '*',
        redirect: '*',
      },
    ],
  },
  {
    path: '/applyList/*',
    component: loadable(
      () => import('../pages/applyList')
    ),
    children: [
      {
        path: 'detail',
        component: loadable(
          () => import('../pages/applyList/detail')
        )
      },
      {
        path: '*',
        redirect: '*',
      },
    ],
  },

  {
    path: '/todoList/*',
    component: loadable(
      () => import('../pages/todoList')
    ),
    children: [
      {
        path: 'add',
        component: loadable(
          () => import('../pages/todoList/add')
        )
      },
      {
        path: 'detail',
        component: loadable(
          () => import('../pages/todoList/detail')
        )
      },
      {
        path: '*',
        redirect: 'add',
      },
    ],
  },

  {
    path: '/doneList/*',
    component: loadable(
      () => import('../pages/doneList')
    ),
    children: [
      {
        path: 'add',
        component: loadable(
          () => import('../pages/doneList/add')
        )
      },
      {
        path: 'detail',
        component: loadable(
          () => import('../pages/doneList/detail')
        )
      },
      {
        path: '*',
        redirect: 'add',
      },
    ],
  },

  {
    path: '/approve/*',
    component: loadable(
      () => import('../pages/approve'),
      
    ),
    children: [
      {
        path: 'detail',
        component: loadable(
          () => import('../pages/approve/detail')
        )
      },
      {
        path: '*',
        redirect: '*',
      },
    ],
  },

  {
    path: '/proofing/*',
    component: loadable(
      () => import('../pages/proofing')
    ),
    children: [
      {
        path: 'add',
        component: loadable(
          () => import('../pages/proofing/add')
        )
      },
      {
        path: 'detail',
        component: loadable(
          () => import('../pages/proofing/detail')
        )
      },
      {
        path: '*',
        redirect: 'add',
      },
    ],
  },

  {
    path: '/order/*',
    component: loadable(
      () => import('../pages/order')
    ),
    children: [
      {
        path: 'add',
        component: loadable(
          () => import('../pages/order/add')
        )
      },
      {
        path: 'detail',
        component: loadable(
          () => import('../pages/order/detail')
        )
      },
      {
        path: '*',
        redirect: 'add',
      },
    ],
  },

  {
    path: '/settings/*',
    component: loadable(
      () => import('../pages/settings')
    ),
  },

  {
    path: '/login/*',
    component: loadable(
      () => import('../pages/login')
    ),
  },

  {
    path: '*', // 未定义的地址跳转目的
    redirect: '/home',
  },
];

function RouterWrapper() {
  return (
    <Router>
      <Routes>{renderRoute(routes)}</Routes>
    </Router>
  );
}
export default RouterWrapper;
